import React from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import i18n from "i18next";

const Cardstorage = () => {
    var lang = i18n.language;
    const { t } = useTranslation();
    return (
        <>
            <NavLink to={t('url.com-batteries')} className='card-link'>
                <div className="card">
                    <img src={`${process.env.PUBLIC_URL + '/images/cards/storage.png'}`} alt="" />
                    <div className="card-text">
                        <h3>{t('pages.combatteries')}</h3>
                    </div>
                    <div className="readmore">
                        <NavLink to={t('url.com-batteries')} exact>{t('storage.product-text-4')}</NavLink>
                    </div>
                </div>
            </NavLink>
            <div className="card-shadow">
                <img src={`${process.env.PUBLIC_URL}/images/cards/shadow.png`} alt="shadow card" />
            </div>
        </>
    )
}

export default Cardstorage