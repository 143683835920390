import React from "react";
import "./LegalNotice.css";

import { useTranslation } from "react-i18next";
import i18n from "i18next";

const LegalNotice = () => {
  const { t } = useTranslation();

  document.title = t("page-title.legalnotice");

  var lang = i18n.language;

  return (
    <div className="UILegalNotice">
      <div className="container-fluid background">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="heading">
                <h1>{t("pages.legalnotice")}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container content">
        <div className="text">
          <h4 className="text-center text-uppercase">{t("legal-notice.main-title")}</h4>
          <h5 className="text-center mb-5">{t("legal-notice.sub-title")}</h5>

          <p>{t("legal-notice.intro-text")}</p>

          <h3>{t("legal-notice.title-1")}</h3>
          <p dangerouslySetInnerHTML={{ __html:t("legal-notice.text-1")}}/>

          <h3>{t("legal-notice.title-2")}</h3>
          <p className="mb-2">{t("legal-notice.text-2-1")}</p>
          <ul>
            <li>{t("legal-notice.text-2-li-1")}</li>
            <li>{t("legal-notice.text-2-li-2")}</li>
            <li>{t("legal-notice.text-2-li-3")}</li>
          </ul>
          <p>{t("legal-notice.text-2-2")}</p>

          <h3>{t("legal-notice.title-3")}</h3>
          <p className="mb-2">{t("legal-notice.text-3-1")}</p>
          <ul>
            <li>{t("legal-notice.text-3-li-1")}</li>
            <li>{t("legal-notice.text-3-li-2")}</li>
            <li>{t("legal-notice.text-3-li-3")}</li>
          </ul>

          <h3 className="mt-4">{t("legal-notice.title-4")}</h3>
          <p>{t("legal-notice.text-4")}</p>

          <h3>{t("legal-notice.title-5")}</h3>
          <p>{t("legal-notice.text-5")}</p>

          <h3>{t("legal-notice.title-6")}</h3>
          <p>{t("legal-notice.text-6")}</p>

          <h3>{t("legal-notice.title-7")}</h3>
          <p>{t("legal-notice.text-7")}</p>

          <h3>{t("legal-notice.title-8")}</h3>
          <p>{t("legal-notice.text-8")}</p>

          <h3>{t("legal-notice.title-9")}</h3>
          <p className="mb-2">{t("legal-notice.text-9-1")}</p>
          <ul>
            <li>{t("legal-notice.text-9-li-1")}</li>
            <li>{t("legal-notice.text-9-li-2")}</li>
            <li>{t("legal-notice.text-9-li-3")}</li>
            <li>{t("legal-notice.text-9-li-4")}</li>
            <li>{t("legal-notice.text-9-li-5")}</li>
          </ul>
          <p dangerouslySetInnerHTML={{ __html:t("legal-notice.text-9-2")}}/>
          <p>{t("legal-notice.text-9-3")}</p>
        </div>
      </div>
    </div>
  );
};

export default LegalNotice;
