import React from 'react'
import './ComBatteries.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Cardflex from './Elements/Cardflex';
import Cardsupply from './Elements/Cardsupply';
import Cardsolar from './Elements/Cardsolar';
import VideoLower from './Elements/VideoLower';

const ComBatteries = () => {
  var lang = i18n.language;
  const { t } = useTranslation();

  document.title = t("page-title.combatteries");

  return (
    <div className="UIComBatteries">
      <div className="container-fluid background">
        <div className="bg-image">
          <VideoLower />
        </div>
        <div className="container">
          <div className="heading">
            <h2>{t('tso.ngenponudba')}</h2>
            <h1>{t('pages.combatteries')}</h1>
          </div>
        </div>
      </div>
      <div className={lang + " container hero-image"}>
        <img src={`${process.env.PUBLIC_URL  + '/images/' + lang + "/products-header-image.png"}`} alt={t('pages.combatteries')} className='starter-whatwedo' />
      </div>
      <div className="overlay">
        <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tl.svg`} alt="" />
      </div>
      <div className="container content">
        <div className="text">
          <h4>{t('companies.heading1')}</h4>
          <p>{t('companies.text-1')}</p>
          <p>{t('companies.text-2')}</p>
        </div>
        {lang.includes('_si') && (
          <div className="text">
            <img src={`${process.env.PUBLIC_URL + '/images/' + lang + '/samooskrba.png'}`} alt="Ngen star shema" width="100%" />
          </div>
        )}
        {lang.includes('_pl') && (
          <div className="text">
            <img src={`${process.env.PUBLIC_URL + '/images/' + lang + '/samooskrba.png'}`} alt="Ngen star shema" width="100%" />
          </div>
        )}
      </div>
      <div className="container-fluid main">
        <div className="container cards">
          <div className="heading">
            <h4>{t('tso.ngenponudba')}</h4>
            <h3>{t('tso.lookat')}</h3>
          </div>
          <div className="row cards">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardflex />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardsolar />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardsupply />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default ComBatteries