import React from 'react'
import './BBSS.css';
import { useTranslation } from 'react-i18next';
import Cardrci from './Elements/Cardrci';
import Cardeec from './Elements/Cardeec';
import VideoLower from './Elements/VideoLower';
import i18n from 'i18next';

const BBSS = () => {
  var lang = i18n.language;
  const { t } = useTranslation();

  document.title = t("page-title.bbss");

  return (
    <div className="UIBBSS">
      <div className="container-fluid background">
        <div className="bg-image">
          <VideoLower />
        </div>
        <div className="container">
          <div className="heading">
            <h2>{t('storage.ngen-products')}</h2>
            <h1>{t('storage.bess-battery-storage-systems')}</h1>
          </div>
        </div>
      </div>
      <div className="container hero-image">
        <img src={`${process.env.PUBLIC_URL + '/images/' + lang + "/products-header-image.png"}`} alt="" className='starter-whatwedo' />
      </div>
      <div className="overlay">
        <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tl.svg`} alt="" />
      </div>
      <div className="container content">
        <div className="text">
          <p>{t('storage.product-text-1')}</p>
          <p>{t('storage.product-text-2')}</p>
        </div>
      </div>
      <div className="container-fluid main">
      <div className="container cards">
        <div className="heading">
          <h4>{t('storage.other-products')}</h4>
          <h3>{t('storage.you-might-also-like')}</h3>
        </div>
        <div className="row cards">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <Cardeec />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <Cardrci />
          </div>
        </div>
        
        </div>
      </div>
    </div >
  )
}
export default BBSS