import React from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Cardtso = () => {
    const { t } = useTranslation();
    return (
        <>
            <NavLink to={t('url.tso')} className='card-link'>
                <div className="card">
                    <img src={`${process.env.PUBLIC_URL}/images/cards/product-tso.png`} alt="TSO" />
                    <div className="card-text">
                        <h3>{t('pages.tso')}</h3>
                    </div>
                    <div className="readmore">
                        <NavLink to={t('url.tso')}>{t('products.readmore')}</NavLink>
                    </div>
                </div>
            </NavLink>
            <div className="card-shadow">
                <img src={`${process.env.PUBLIC_URL}/images/cards/shadow.png`} alt="shadow card" />
            </div>
        </>
    )
}

export default Cardtso