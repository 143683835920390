import React from 'react'
import './WhoWeAre.css';
import { useTranslation } from 'react-i18next';
import VideoLower from './Elements/VideoLower';
import i18n from 'i18next';
const WhoWeAre = () => {
  const { t } = useTranslation();

  document.title = t("page-title.whoweare");

  return (
    <div className="UIWhoWeAre">
      <div className="container-fluid background">
        <div className="bg-image">
          <VideoLower />
        </div>
        <div className="container">
          <div className="heading">
            <h2>{t('whoweare.predstavitev')}</h2>
            <h1>{t('whoweare.kdo-smo')}</h1>
          </div>
        </div>
      </div>
      <div className="container hero-image">
        <img src={`${process.env.PUBLIC_URL}/images/whoweare-header-image.png`} alt="" className='starter-whatwedo' />
      </div>
      <div className="overlay">
        <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tl.svg`} alt="" />
      </div>
      <div className="container content">
        <div className="text">
          <p>{t('whoweare.text-1')}</p>
          <p>{t('whoweare.text-2')}</p>
          <p>{t('whoweare.text-3')}</p>
        </div>
      </div>
      <div className="container video">
        <video id="video" controls poster={`${process.env.PUBLIC_URL}/images/cartoon.jpg`} controlsList="nodownload">
          <source src={`${process.env.PUBLIC_URL + t('home.cartoonURL')}`} type="video/mp4" />
        </video>
      </div>
    </div >
  )
}

export default WhoWeAre