import React from 'react'
import './ComSolar.css';
import { useTranslation } from 'react-i18next';
import Cardstorage from './Elements/Cardstorage';
import Cardflex from './Elements/Cardflex';
import Cardsupply from './Elements/Cardsupply';
import VideoLower from './Elements/VideoLower';
import i18n from 'i18next';

const ComSolar = () => {
  const { t } = useTranslation();

  document.title = t("page-title.comsolar");

  return (
    <div className="UIComSolar">
      <div className="container-fluid background">
        <div className="bg-image">
          <VideoLower/>
        </div>
        <div className="container">
          <div className="heading">
            <h2>{t('tso.ngenponudba')}</h2>
            <h1>{t('pages.comsolar')}</h1>
          </div>
        </div>
      </div>
      <div className="container hero-image">
        <img src={`${process.env.PUBLIC_URL}/images/solar-products-header-image.png`} alt={t('pages.com-solar')} className='starter-whatwedo' />
      </div>
      <div className="overlay">
        <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tl.svg`} alt="" />
      </div>
      <div className="container content">
        <div className="text">
          <h4>{t('companies.heading3')}</h4>
          <p>{t('companies.text-4')}</p>
          <p>{t('companies.text-6')}</p>
        </div>
      </div>
      <div className="container-fluid main">
      <div className="container cards">
        <div className="heading">
          <h4>{t('tso.ngenponudba')}</h4>
          <h3>{t('tso.lookat')}</h3>
        </div>
        <div className="row cards">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <Cardstorage />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <Cardflex />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <Cardsupply />
          </div>
        </div>
        </div>
      </div>
    </div >
  )
}

export default ComSolar