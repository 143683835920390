import React, { useEffect } from "react";
import "./Households.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Cardtso from "./Elements/Cardtso";
import Cardcompanies from "./Elements/Cardcompanies";
import Cardstar from "./Elements/Cardstar";
import VideoLower from "./Elements/VideoLower";
import { NavLink } from "react-router-dom";

const Households = () => {
  const { t } = useTranslation();
  var lang = i18n.language;

  document.title = t("page-title.households");

  return (
    <div className="UIHouseholds">
      <div className="container-fluid background">
        <div className="bg-image">
          <VideoLower />
        </div>
        <div className="container">
          <div className="heading">
            <h2>{t("tso.ngenponudba")}</h2>
            <h1>{t("pages.gospodinjstva")}</h1>
          </div>
        </div>
      </div>
      <div className="container hero-image">
        <img
          src={`${process.env.PUBLIC_URL}/images/products/households.png`}
          alt=""
          className="starter-whatwedo"
        />
      </div>
      <div className="overlay">
        <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tr.svg`} alt="" />
      </div>

      {lang.includes("sl_") && (
        <div className="container content">

            <div className="text">
            <h4 id="bess">{t("households.heading1")}</h4>
            <p>{t("households.text-1")}</p>
            <p>{t("households.text-2")}</p>
            <p>{t("households.text-3")}</p>
            <p>{t("households.text-4")}</p>
            <p>{t("households.text-5")}</p>
            <p>{t("households.text-6")}</p>
            <div className="text">
              <img
                src={`${
                  process.env.PUBLIC_URL + "/images/" + lang + "/samooskrba.png"
                }`}
                alt="Ngen star shema"
                width="100%"
              />
            </div>

            <h4>{t("households.heading2")}</h4>
            <p>{t("households.text-7")}</p>
            <br />
            {lang.includes("_si") && (
              <a
                href="https://star.ngen.si/sl"
                className="btn-primary"
                target="_blank"
              >
                {t("households.button-sun")}
              </a>
            )}
            {lang.includes("_si") && (
              <div>
                <h4>{t("households.heading4")}</h4>
                <p>{t("households.text-15")}</p>
                <img
                  src={`${process.env.PUBLIC_URL}/images/stevec_01.png`}
                  alt="Pametni števec"
                  width="45%"
                />
              </div>
            )}

            <h4 id="doee">{t("households.heading3")}</h4>
            <p>{t("households.text-8")}</p>
            <br />
            
            <NavLink to={t("url.supplyHouseholds")} className="btn-primary" exact>
              {t("households.button-more")}
            </NavLink>

            <h4>{t("households.text-9")}</h4>
            <p>{t("households.text-10")}</p>

            <h4>{t("households.text-11")}</h4>
            <p>{t("households.text-12")}</p>

            <br />
            <NavLink to={t("url.ngenconnection")} className="btn-primary" exact>
              {t("households.text-13")}
            </NavLink>
          </div>
        </div>
      )}

      {!lang.includes("sl_") && (
        <div className="container content">
          <div className="text">
            <p>{t("households.text-16")}</p>
            <h4 id="bess">{t("households.heading1")}</h4>
            <p>{t("households.text-1")}</p>
            <p>{t("households.text-2")}</p>
            <p>{t("households.text-3")}</p>
            <p>{t("households.text-4")}</p>
            <p>{t("households.text-5")}</p>
            <p>{t("households.text-6")}</p>

            {lang.includes("de_") && (
              <div className="text">
                <img
                  src={`${
                    process.env.PUBLIC_URL +
                    "/images/" +
                    lang +
                    "/samooskrba.png"
                  }`}
                  alt="Ngen star shema"
                  width="100%"
                />
              </div>
            )}

            {lang.includes("en_") && (
              <div className="text">
                <img
                  src={`${
                    process.env.PUBLIC_URL +
                    "/images/" +
                    lang +
                    "/samooskrba.png"
                  }`}
                  alt="Ngen star shema"
                  width="100%"
                />
              </div>
            )}

            <h4 id="doee">{t("households.heading2")}</h4>
            <p>{t("households.text-7")}</p>
            <h4>{t("households.heading4")}</h4>
            <p>{t("households.text-15")}</p>
            <p>{t("households.text-17")}</p>
            <img
              src={`${process.env.PUBLIC_URL}/images/stevec_01.png`}
              alt="Pametni števec"
              width="45%"
            />
            <br />           
            <h4>{t("households.text-9")}</h4>
            <p>{t("households.text-10")}</p>
            <h4>{t("households.text-11")}</h4>
            <p>{t("households.text-12")}</p>
            <br />
          </div>
        </div>
      )}

      <div className="container-fluid main">
        <div className="container cards">
          <div className="heading">
            <h4>{t("tso.lookat")}</h4>
            <h3>{t("tso.other-products")}</h3>
          </div>
          <div className="row cards">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardtso />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardcompanies />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardstar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Households;
