import React, { Component } from 'react';
import i18n from 'i18next';

class FormSupportPortugal extends Component {
  state = {
    name: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
    emailStatus: '',
    language: i18n.language
  };

  // handle the value
  handleCHange = input => e => {
    this.setState({ [input]: e.target.value });
  }

  // when submit btn is clicked
  submitForm = (e) => {
    var lang = i18n.language;
    const { name, lastname, email, phone, message, language } = this.state;


    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener('load', () => {
      // update the response state and the step

      this.setState({
        emailStatus: xhr.responseText
      });
    });
    // open the request with the verb and the url
    if (lang.includes('_pt')) {
      xhr.open('GET', '/api/index_pt_support.php?email=' + email +
        '&phone=' + phone +
        '&name=' + name +
        '&lastname=' + lastname +
        '&message=' + message +
        '&lang=' + language);
      // send the request
      xhr.send();
    } 

    // reset the fields
    this.setState({
      name: '',
      lastname: '',
      email: '',
      phone: '',
      message: ''
    });
    e.preventDefault();
  }

  render() {
    const { name, lastname, email, phone, message, emailStatus } = this.state;
    return (
      <>
        <form onSubmit={this.submitForm}>
          <div className="row">
            <div className="col-md-6 form-element">
              <label htmlFor="name">{i18n.t('contact.name')}</label>
              <input type="text" name="name" id="name" value={name} onChange={this.handleCHange('name')} required />
            </div>
            <div className="col-md-6 form-element">
              <label htmlFor="lastname">{i18n.t('contact.lastname')}</label>
              <input type="text" name="lastname" id="lastname" value={lastname} onChange={this.handleCHange('lastname')} required />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-element">
              <label htmlFor="email">{i18n.t('contact.email')}</label>
              <input type="email" name="email" id="email" value={email} onChange={this.handleCHange('email')} required />
            </div>
            <div className="col-md-6 form-element">
              <label htmlFor="name">{i18n.t('contact.phone')}</label>
              <input type="tel" name="phone" id="phone" value={phone} onChange={this.handleCHange('phone')} required />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-element">
              <label htmlFor="message">{i18n.t('contact.message')}</label>
              <textarea name="message" id="message" cols="40" rows="6" value={message} onChange={this.handleCHange('message')} required></textarea>
            </div>
          </div>
          <div className="container">
            <input type="submit" className="btn-primary float-right" value={i18n.t('contact.send')} />
          </div>
          <div className="mail-info">{emailStatus ? emailStatus : null}</div>
        </form>
      </>
    );
  }
}

export default FormSupportPortugal;