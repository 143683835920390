import React from "react";
import "./PrivacyPolicy.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  document.title = t("page-title.privacy-policy");

  var lang = i18n.language;

  return (
    <div className="UIPrivacyPolicy">
      <div className="container-fluid background">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="heading">
                <h1>{t("pages.privacypolicy")}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container content">        
          <div className="text m-2">          
          <div className="text mb-3">
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.intro_1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.intro_2") }} />
          </div>
          <div className="text mb-3">
            <h4 className="text-uppercase" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_1") }} />            
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_1_1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_1_2") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_1_3") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_1_4") }} />            
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_1_5") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_1_6") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_1_7") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_1_8") }} />
          </div>
          <div className="text mb-3">
            <h4 className="text-uppercase" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_2") }} />
            <h3 className="mt-5" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_2_1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_1a") }} />
            <ul>
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_li_1") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_li_2") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_li_3") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_li_4") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_li_5") }} />              
            </ul>
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_1b") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_2_1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_2_2") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_2_3") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_2_4") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_2_5") }} />
            <ul>
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_2_5_li_1") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_2_5_li_2") }} />
            </ul>
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_2_6") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_2_7") }} />
            <ul>
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_2_7_li_1") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_2_7_li_2") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_1_2_7_li_3") }} />
            </ul>
            <h3 className="mt-5" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_2_2") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_1") }} />
            <ul>
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_1_li_1") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_1_li_2") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_1_li_3") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_1_li_4") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_1_li_5") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_1_li_6") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_1_li_7") }} />              
            </ul>
            <p className="mt-5 mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_2") }} />
            <ul>
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_2_li_1") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_2_li_2") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_2_li_3") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_2_2_li_4") }} />              
            </ul>
            <h3 className="mt-5" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_2_3") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_3_1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_3_2") }} />
            <h3 className="mt-5" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_2_4") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_4_1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_4_2") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_4_3") }} />
            <h3 className="mt-5" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_2_5") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_5_1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_5_2") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_5_3") }} />
            <ul>
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_5_3_li_1") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_5_3_li_2") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_5_3_li_3") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_5_3_li_4") }} />
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_5_3_li_5") }} />
            </ul>
            <h3 className="mt-5" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_2_6") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_6_1") }} />
            <h3 className="mt-5" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_2_7") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_7_1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_7_2") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_7_3") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_7_4") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_7_5") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_7_6") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_7_7") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_7_8") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_7_9") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_2_7_10") }} />
          </div>
          <div className="text mb-3">
            <h4 className="text-uppercase" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_3") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_3_1") }} />
          </div>
          <div className="text mb-3">
            <h4 className="text-uppercase" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_4") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_4_1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_4_2") }} />
          </div>
          <div className="text mb-3">
            <h4 className="text-uppercase" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_5") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_5_1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_5_2") }} />
          </div>
          <div className="text mb-3">
            <h4 className="text-uppercase" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_6") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_6_1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_6_2") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_6_3") }} />
          </div>
          <div className="text mb-3">
            <h4 className="text-uppercase" dangerouslySetInnerHTML={{ __html: t("privacy-policy.title_7") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.text_7_1") }} />
          </div>
        </div>       
      </div>
    </div>
  );
};

export default PrivacyPolicy;
