import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const path = process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json';

const languageDetector = {
    type: 'languageDetector',
    async: true,
    detect: (callback) => {
        const language = window.location.pathname.split('/')[1];
        callback(language);
    },
    init: () => {},
    cacheUserLanguage: () => {}
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['translation'],
    defaultNS: 'translation',
    supportedLngs: [
      'sl_si', 'en_si',
      'en_at', 'de_at',
      'en_de', 'de_de',
      'pl_pl', 'en_pl',
      'es_es', 'en_es',
      'pt_pt', 'en_pt',
      'hr_hr',
      'en_gb'
    ],
    interpolation: {
      escapeValue: false,
    },
    whitelist: [
      'sl_si', 'en_si', 
      'en_at', 'de_at',
      'en_de', 'de_de',
      'pl_pl', 'en_pl',
      'es_es', 'en_es',
      'pt_pt', 'en_pt',
      'hr_hr',
      'en_gb'
    ],
    fallbackLng: 'en_si',
    detection: languageDetector,
    backend: {
      loadPath: path
  }
  });


  i18n.on('languageChanged', (lng) => { 
    localStorage.setItem('language', lng);
    document.documentElement.setAttribute('lang', lng); 
  })
